import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import RealEstateList from '../views/RealEstateList.vue'
import Messages from '../views/Messages.vue'
import Wasserquali from '../views/Wasserquali.vue'
import Einstellungen from '../views/Einstellungen.vue'
import Verbrauch from '../views/Verbrauch.vue'
import Warnungen from '../views/Warnungen.vue'
import Versicherung from '../views/Versicherung.vue'



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "Dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
  },
  {
    path: "/wasserquali",
    name: "Wasserquali",
    component: Wasserquali,
  },
  {
    path: "/Verbrauch",
    name: "Verbrauch",
    component: Verbrauch,
  },
  {
    path: '/Einstellungen',
    name: 'Einstellungen',
    component: Einstellungen,
  },
  {
    path: "/immobilien",
    name: "Immobilien",
    component: RealEstateList,
  },
  {
    path: "/warnungen",
    name: "Warnungen",
    component: Warnungen,
  },
  {
    path: "/versicherung",
    name: "Versicherung",
    component: Versicherung,
  },
  {
    path: "/real-estate/list",
    name: "RealEstateList",
    redirect: "Immobilien",
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
