<template>
  <div class="card-list">
        <v-simple-table dark class="card-table">
          <thead>
            <tr>
              <th v-for="(head, idx) in value.TableHeader" :key="idx">
                {{ head }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, idx) in value.TableData" :key="idx">
              <td v-for="item in row" :key="item">{{ item }}</td>
              <td class="card-table-icon">📄</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
</template>

<script>
export default {
    props:[
        "value"
    ]
}
</script>

<style>
.card-list {
  width: 100%;
  height: 85%;
  margin-top: 1rem;
  margin-bottom: auto;
}

.card-list table td,
th {
  text-align: center !important;
}
th{
  font-size: 1.04rem !important;
}
.card-table-icon {
  font-size: 1.2rem !important;
  cursor: pointer;
}

</style>