<template>
  <div class="versicherungs_wrapper">
    <v-card class="versicherungen_card">
      <h1>Versicherungsbericht</h1>
      <div class="content">
        <div>
          <v-card color="rgb(70,70,70)" style="padding: 1rem; margin: 1rem">
            <h4>Geschädigter</h4>
            <v-text-field
              label="Name"
              :rules="rules"
              v-model="name"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              label="Straße"
              :rules="rules"
              v-model="strasse"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              label="Ort"
              :rules="rules"
              v-model="ort"
              hide-details="auto"
            ></v-text-field>
          </v-card>
        </div>
        <div>
          <v-card color="rgb(70,70,70)" style="padding: 1rem; margin: 1rem">
            <h4>Ansprechpartner</h4>
            <v-text-field
              label="Name"
              :rules="rules"
              value="Wohnlich mit Wein GmbH"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              label="Straße"
              :rules="rules"
              value="Beraterstraße 12"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              label="Ort"
              :rules="rules"
              value="33098 Paderborn"
              hide-details="auto"
            ></v-text-field>
          </v-card>
        </div>
        <v-card color="rgb(70,70,70)" style="padding: 1rem; margin: 1rem">
            <h4>Versicherungsdaten</h4>

          <v-checkbox :label="`Log an Versichung Übertragen`" v-model="check"></v-checkbox>
          <v-checkbox :label="`MwSt. – Erstattung`" class="asasd" v-model="check_2"></v-checkbox>
          <v-text-field
            label="Versicherungsnummer"
            hide-details="auto"
            value="12312-2323-54322"
          ></v-text-field>
          <v-text-field
            label="Schadensursache"
            hide-details="auto"
            value="Rohrbruch"
          ></v-text-field>
        </v-card>
        <v-date-picker v-model="date1" color="black"></v-date-picker>
      </div>
      <div class="asasd">
      <router-link to="/warnungen"><v-btn @click="$store.state.insurance_send = true">Absenden</v-btn></router-link>

      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    arrayEvents: null,
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    name: "Nico Bellic",
    strasse: "Lindberghring 1",
    ort: "33142 Büren",
    check: true,
    check_2: true
  }),
};
</script>
<style scoped>
h1 {
  font-weight: 400;
}
.versicherungs_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  background-color: rgb(17, 17, 17);
}
.versicherungen_card {
  min-height: 70%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 2rem;


}
.content {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2rem;
}
.asasd{
    margin-top: 1rem;
}
</style>