<template>
  <div>
    <canvas :id="`bar_chart_${_uid}`"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  data() {
    return {
      id: null,
    };
  },
  props: [
      'labels',
      'rgb',
      'title'
  ],
  methods: {
    create_bar_chart(id) {
      const data = {
        labels: this.labels,

        datasets: [
          {
            label: this.label,
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: true,
            backgroundColor: `rgba(${this.rgb[0]}, ${this.rgb[1]}, ${this.rgb[2]}, 0.2)`,
            borderColor: `rgba(${this.rgb[0]}, ${this.rgb[1]}, ${this.rgb[2]}, 1.0)`,
            pointBackgroundColor: "rgba(252, 238, 79, 1)",
            pointBorderColor: "rgba(0,0,0, 0.2)",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0, 0,0)",
            borderWidth: 1,
          },
        ],
      };
      const config = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
             title: {
                display: true,
                text: this.title,
                color: "white"
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
            },
          },
        },
      };
      setTimeout(function () {
        console.log(this.id);

        console.log(document.getElementById(`bar_chart_${id}`));
        new Chart(document.getElementById(`bar_chart_${id}`), config);
      }, 100);
    },
  },
  mounted() {
    this.id = this._uid;
    this.create_bar_chart(this.id);
  },
};
</script>

<style scoped>
div {
  height: 100%;
  width: 100%;
}
</style>