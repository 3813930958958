<template>
  <div class="dashboard-wrapper">
    <v-card
      class="card"
      elevation="2"
      v-for="value in metrics"
      :key="value.Name"
      @click="'Link' in value ? $router.push(value.Link) : () => {}"
      :ripple="'Link' in value ? true : false"
      :class="[
        'Height' in value ? `grid-height-${value.Height}` : 'blub',
        'Width' in value ? `grid-width-${value.Width}` : 'blub',
      ]"
    >
      <div class="card_header">
        <div class="material-icons-round card_icon">{{ value["Icon"] }}</div>
        <h2 class="card-headline">{{ value["Headline"] }}</h2>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="material-icons-round card_icon_right"
              v-bind="attrs"
              v-on="on"
              >info</span
            >
          </template>
          <span>Right tooltip</span>
        </v-tooltip>
      </div>

      <DashboardCardList
        :value="value"
        v-if="'Type' in value && value.Type === 'List'"
      ></DashboardCardList>

      <DashboardCardSwitch
        v-else-if="'Type' in value && value.Type === 'Switches'"
      ></DashboardCardSwitch>

      <div
        v-else-if="'Type' in value && value.Type === 'Radial'"
        class="card-item-content"
      >
        <canvas id="testChart"></canvas>
      </div>

      <div
        v-else-if="'Type' in value && value.Type === 'Warnung'"
        class="card-item-content up-to-down"
      >
        <p class="card_headline">{{ value["Name"] }}</p>
        <div class="material-icons-round icon_larger" :style="{ color: $store.state.warnung.Color }">{{ $store.state.warnung.Icon }}</div>
        <div
          class="card_text left_to_right"
          :style="{ color: $store.state.warnung.Color}"
          v-for="(message, idx) in $store.state.warnung.Messages"
          :key="idx"
        >
          <span class="material-icons-round">{{ message.Icon }}</span>
          <p class="card_text"
          style="text-align:left; margin-left: 1rem"
          :style="{'font-size': '1.7rem'} "
          >{{ message.Message }}</p>
        </div>
      </div>

      <div v-else class="card-content">
        <p class="card_headline">{{ value["Name"] }}</p>
        <p
          class="card_text"
          :style="{ color: value['ValueColor'] }"
          v-html="value['Value']"
        ></p>
      </div>
    </v-card>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import DashboardCardSwitch from "./../components/DashboardCardSwitch.vue";
import DashboardCardList from "./../components/DashboardCardList.vue";

export default {
  name: "Home",

  components: {
    DashboardCardSwitch,
    DashboardCardList,
  },

  computed: {},

  methods: {
    create_demo_chart() {
      const data = {
        labels: [
          "Wasserqualität",
          "Zirkulation",
          "Wartung",
          "Zustand",
          "Ultrafiltration",
        ],
        datasets: [
          {
            label: "Metrik Zusammenfassung",
            data: [98, 81, 18, 93, 100],
            fill: true,
            backgroundColor: "rgba(252, 238, 79, 0.2)",
            borderColor: "rgba(252, 238, 79, 1.0)",
            pointBackgroundColor: "rgba(252, 238, 79, 1)",
            pointBorderColor: "rgba(0,0,0, 0.2)",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0, 0,0)",
          },
        ],
      };
      const config = {
        type: "radar",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              angleLines: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              pointLabels: {
                color: "rgba(252, 238, 79, 1)",
              },
              ticks: {
                display: false,
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 3,
            },
          },
        },
      };
      var myChart = new Chart(document.getElementById("testChart"), config);
      myChart;
    },
  },
  async beforeCreate() {
    this.$store.state.socket.emit("appartments", {});
    this.$store.state.socket.emit("houses", {});
    this.$store.state.socket.emit("switches", {});
  },
  mounted() {
    this.create_demo_chart();
  },

  data() {
    return {
      metrics: [
        {
          Name: "Sensoren",
          Icon: "sensors",
          Value: 12321,
        },
        {
          Name: "Immobilien",
          Icon: "holiday_village",
          Value: 7,
          Link: "Immobilien",
        },
        {
          Name: "Nachrichten",
          Icon: "message",
          Value: this.$store.getters.unread_chats,
          Link: "Messages",
        },
        {
          Name: "Wohnungen",
          Icon: "apartment",
          Value: 54,
        },
        {
          Name: "Verbrauch",
          Icon: "data_usage",
          Value: "normal",
          Link: "Verbrauch",
        },
        {
          Name: "Schadenfälle",
          Icon: "water_damage",
          Value: 22,
        },
        {
          Headline: "Letzte Ereignisse",
          Icon: "update",
          Value: 22,
          Height: 2,
          Width: 2,
          Type: "List",
          TableHeader: ["Datum", "Ereignis", "Ort", "Bericht"],
          TableData: [
            ["heute", "Toilettenspülung", "Wohnung 1"],
            ["heute", "Dusche", "Wohnung 1"],
            ["heute", "Waschbecken Bad", "Wohnung 2"],
            ["heute", "Toilettenspülung", "Wohnung 2"],
            ["heute", "Waschbecken Küche", "Wohnung 3"],
            ["heute", "Waschbecken Bad", "Wohnung 1"],
            ["heute", "Toilettenspülung", "Wohnung 1"],
            ["heute", "Waschbecken Bad", "Wohnung 3"],
            ["heute", "Toilettenspülung", "Wohnung 3"],
            ["gestern", "Dusche", "Wohnung 3"],
            ["gestern", "Waschmaschine", "Keller 1"],
          ],
        },
        {
          Headline: "Quick Control©",
          Icon: "invert_colors_off",
          Height: 2,
          Width: 2,
          Type: "Switches",
        },
        {
          Headline: "Warnungen",
          Icon: "warning",
          Value: "Kein Rohrbruch<br>Annomalien festgestellt",
          Height: 2,
          ValueColor: "var(--primary-color)",
          Type: "Warnung",
          Link: "Warnungen"
        },
        {
          Name: "Wasserqualität",
          Icon: "water",
          Value: "Sehr gut (98%)",
          ValueColor: "green",
          Link: "Wasserquali",
        },
        {
          Name: "Wartung",
          Icon: "engineering",
          Value: "Überfällig",
          ValueColor: "orange",
        },
        {
          Headline: "Schnellüberblick",
          Icon: "insights",
          Height: 2,
          Width: 2,
          Type: "Radial",
        },
      ],
    };
  },
};
</script>
<style>
.card-table {
  height: 100%;
  overflow-y: scroll;
}
.as_col {
  display: flex;
  flex-direction: column;
}
.sideinfo {
  font-size: 0.8rem;
}
.card-item-content {
  width: 100%;
  height: 85%;
  margin-top: 1rem;
  display: flex;
}
.col_selected {
  background-color: #616161;
}
.dashboard-wrapper {
  display: grid;
  gap: 1rem;

  grid-template-columns: repeat(auto-fit, minmax(max(18%, 200px), 1fr));
  grid-auto-rows: 240px;
  padding: 1.5rem;
}
</style>
<style>
.card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: start;
  transition: all 0.7s ease-in-out;
}

.card:hover {
  scale: 1.005;
}

.card-content {
  align-content: center;
  justify-content: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: auto;

  font-size: clamp(1rem, 2rem, 2.8rem);
  transition: all 0.5s ease-in-out;
  word-break: break-word;
}

h3 {
  margin: 0;
}

.card_headline {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.card_text {
  font-size: 1.9rem;
  font-weight: 300;
  text-align: center;
}

h3 {
  font-size: 2.5rem;
}
.card-caption {
  color: var(--primary-color);
  grid-row: span 1 / auto;
  font-size: 2rem;
}

.grid-height-2 {
  grid-row: span 2 / auto;
}

.grid-width-2 {
  grid-column: span 2 / auto;
}

.card-headline {
  font-weight: 400;
}

.card_icon {
  padding: 0.3rem;
  font-size: 2.3rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5.5px);
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
  color: var(--primary-color);
}
.card_icon_right {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.25);
}

.card_header {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
}

.material-icons-round {
  font-size: 2.3rem;
  vertical-align: bottom;
}

tbody {
  overflow-y: scroll;
}

tr {
  transition: all 0.2s ease-in-out;
}

.col_selected .card-switch-left-icon {
  color: var(--primary-color);
}
.left_to_right{
  display: flex;
  flex-direction: row;
}
.up-to-down{
    display: flex;
  flex-direction: column;
}
.icon_larger{
  font-size: 12rem;
  align-self: center;
}
</style>
