<template>
  <div class="quali-dashboard">
    <v-card class="card quali-zsm" elevation="2"
      ><div class="card_header">
        <div class="material-icons-round card_icon">water</div>
        <h2 class="card-headline">Zusammenfassung</h2>
        <span class="material-icons-round card_icon_right">more_vert</span>
      </div>
    </v-card>
    <v-expansion-panels
      class="quali-extention-panels"
      v-model="panels"
      multiple
    >
      <v-expansion-panel v-for="(item, idx) in $store.state.houses" :key="idx">
        <v-expansion-panel-header
          @click.once="render_graphs(idx)"
        >
          <div class="quali-house-wrapper">
            <span class="material-icons-round quali-house-icon">{{
              item.Icon
            }}</span
            ><span>{{ item.Name }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="quali-house-content">
            <v-simple-table class="table-quali-values">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Messwert</th>
                    <th class="text-left">Ausprägung</th>
                    <th class="text-left">Bewertung</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in wasser_quali_werte" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ value }}</td>
                    <td>
                      <v-rating
                        background-color="white"
                        color="var(--primary-color)"
                        :value="5"
                        readonly
                      ></v-rating>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="quali-chart">
              <canvas :id="`testChart_${idx}`"></canvas>
            </div>

            <div class="quali-bar-chart">
              <canvas :id="`barChart_${idx}`"></canvas>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  async beforeCreate() {
    this.$store.state.socket.emit("appartments", {});
    this.$store.state.socket.emit("houses", {});
  },
  data() {
    return {
      wasser_quali_werte: {
        "pH-Wert": 7.5,
        Leitfähigkeit: 86,
        "Gelöster Sauerstoff": "12mg/ml",
        Trübung: "55%",
        Chlor: "56mg/ml",
        Legionellen: "7/100ml",
        Nitrat: "28mg/L",
        Summenparameter: 5,
      },
      panels: [],
      headers: [{ value: "pH-Wert", text: "pH-Wert" }],
    };
  },
  methods: {
    render_graphs(idx) {
      this.create_demo_chart(idx);
      this.create_bar_chart(idx);
    },
    create_demo_chart(idx) {
      const data = {
        labels: [
          "pH",
          "Leitfähigkeit",
          "Gelöster Sauerstoff",
          "Trübung",
          "Chlor",
          "Brom",
          "Nitrat",
          "Summenparameter",
        ],
        datasets: [
          {
            label: "Metrik Zusammenfassung",
            data: [98, 81, 32, 93, 100, 87, 93, 86],
            fill: true,
            backgroundColor: "rgba(252, 238, 79, 0.2)",
            borderColor: "rgba(252, 238, 79, 1.0)",
            pointBackgroundColor: "rgba(252, 238, 79, 1)",
            pointBorderColor: "rgba(0,0,0, 0.2)",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0, 0,0)",
          },
        ],
      };
      const config = {
        type: "radar",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              angleLines: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              pointLabels: {
                color: "rgba(252, 238, 79, 1)",
              },
              ticks: {
                display: false,
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 3,
            },
          },
        },
      };
      setTimeout(function () {
        console.log(document.getElementById(`testChart_${idx}`));
        new Chart(document.getElementById(`testChart_${idx}`), config);
      }, 200);
    },
    create_bar_chart(idx) {
      const labels = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
      ];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "My First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: true,
            backgroundColor: "rgba(252, 238, 79, 0.2)",
            borderColor: "rgba(252, 238, 79, 1.0)",
            pointBackgroundColor: "rgba(252, 238, 79, 1)",
            pointBorderColor: "rgba(0,0,0, 0.2)",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0, 0,0)",
            borderWidth: 1,
          },
        ],
      };
      const config = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
            },
          },
        },
      };
      setTimeout(function () {
        console.log(document.getElementById(`barChart_${idx}`));
        new Chart(document.getElementById(`barChart_${idx}`), config);
      }, 200);
    },
  },
  computed: {
    houses() {
      return this.$store.state.houses;
    },
  },
  watch: {
    houses() {},
  },
  mounted() {},
};
</script>

<style scoped>
.quali-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.5rem;
}
.quali-zsm {
  width: 100%;
}
.quali-extention-panels {
  margin-top: 1.2rem;
  width: 100%;
  height: min-content;
}
.quali-house-icon {
  color: var(--primary-color);
  margin-right: 0.7rem;
}
.quali-house-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.quali-house-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.quali-house-content p {
  margin-left: 1rem;
}
.quali-chart {
  width: 30%;
}
.table-quali-values {
  margin-left: 2.4rem;
}
.quali-bar-chart {
  width: 30%;
  min-height: 20rem;
}
</style>