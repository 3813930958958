<template>
  <div class="verbrauch_wrapper">
    <v-simple-table dark class="card-table verbrauch-table">
      <tbody>
        <tr
          v-for="(item, idx) in $store.state.houses"
          :key="idx"
          @click="
            () => {
              selected_switch = idx;
              selected_appartment = 0;
            }
          "
          :class="{ col_selected: idx == selected_switch }"
        >
          <td>
            <div class="card-switch-left-content">
              <span class="material-icons-round card-switch-left-icon">{{
                item.Icon
              }}</span>
              <div class="card-switch-left-text">
                <span class="appartment_name"> {{ item["Name"] }}</span
                ><br />
                {{ item.Straße }}<br />
                {{ item.Ort }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table
      dark
      class="card-table verbrauch-table"
      style="margin-left: 1.5rem"
    >
      <tbody>
        <tr
          v-for="(item, idx) in $store.state.appartments[selected_switch]"
          :key="idx"
          @click="selected_appartment = idx"
          :class="{ col_selected: idx == selected_appartment }"
        >
          <td>
            <div class="card-switch-left-content">
              <span class="material-icons-round card-switch-left-icon"
                >house</span
              >
              <div class="card-switch-left-text">
                <span class="appartment_name"> {{ item["Name"] }}</span
                ><br />
                {{ item["Mieter"] }}<br />
                {{ item["Telefon"] }}<br />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-card class="verbrauch_card">
      <h1>Verbrauch</h1>
      <v-simple-table class="asdkasif">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Wert</th>
              <th class="text-left">Ausprägung</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in stat" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.calories }}</td>
              <td>{{ item.aus }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="verbrauch_charts">
        <BarChart
          :labels="labels"
          :rgb="$store.state.colors.water"
          :title="'Wasserverbrauch'"
        /><BarChart
          :labels="labels"
          :rgb="$store.state.colors.energy"
          :title="'Stromverbrauch'"
        />
      </div>
    </v-card>
    <div></div>
  </div>
</template>

<script>
import BarChart from "../components/BarChart.vue";

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      selected_switch: 0,
      selected_appartment: 0,
      labels: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli"],
      rgb: [255, 0, 0],
      stat: [
        {
          name: "Ø Veränderung zum Vorjahr (Wasser)",
          calories: "-12%",
          aus: "107L/Tag",
        },
        {
          name: "Ø Veränderung zum Vorjahr (Strom)",
          calories: "+6%",
          aus: "3.54kWh/Tag",
        },
        {
          name: "Wassserverbrauch/Jahr",
          calories: "-12%",
          aus: "38.092 Liter (38m3)",
        },
        {
          name: "Stromverbrauch/Jahr",
          calories: "-12%",
          aus: "1312kWh",
        },
      ],
    };
  },
  async beforeCreate() {
    this.$store.state.socket.emit("appartments", {});
    this.$store.state.socket.emit("houses", {});
    this.$store.state.socket.emit("switches", {});
  },
};
</script>

<style scoped>
.asdkasif {
  width: 80%;
  margin: 2rem;
}
.verbrauch_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem;
}
.verbrauch-table {
  height: 100%;
  max-height: 85vh;
  min-width: 25%;
  overflow: scroll;
}
.appartment_name {
  font-size: 1.04rem;
}
.verbrauch_card {
  height: 100%;
  max-height: 85vh;
  margin-left: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
}
.verbrauch_charts {
  display: flex;
  flex-direction: row;
  height: 12rem;
}
</style>