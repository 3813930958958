<template>
  <v-row>
    <v-col cols="3">
      <v-card>
        <v-list>
          <v-list-item-group v-model="selectedHouse" color="primary" @change="()=>{selectedAppartment = 0; create_demo_chart()}" mandatory>
            <v-list-item v-for="(item, idx) in $store.state.houses" :key="idx">
              <v-list-item-icon>
                <v-icon> mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.Name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.Straße }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.Ort }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-list>
          <v-list-item-group v-model="selectedAppartment" color="primary" @change="create_demo_chart" mandatory>
            <v-list-item
              v-for="(item, idx) in $store.state.switches[selectedHouse]"
              :key="idx"
            >
              <v-list-item-icon>
                <v-icon> mdi-home-floor-1</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.Name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card :key="`${selectedHouse}_${selectedAppartment}`">
        <div>
          <div class="top-right">
            <v-switch
              v-model="
                $store.state.switches[selectedHouse][selectedAppartment][
                  'Status'
                ]
              "
              @click="changeSwitch(selectedHouse, selectedAppartment)"
            ></v-switch>
          </div>
          <v-card-title>
            {{ $store.state.switches[selectedHouse][selectedAppartment].Name }}
          </v-card-title>
          <v-card-subtitle>
            {{ $store.state.houses[selectedHouse].Name }}
          </v-card-subtitle>
          <v-card-text>
            <v-chip pill
              ><v-avatar> <v-icon>mdi-account</v-icon></v-avatar>
              {{
                $store.state.appartments[selectedHouse][selectedAppartment]
                  .Mieter
              }}</v-chip
            >
          </v-card-text>
          <v-card-text>
            <canvas :id="`testChart`"></canvas>
          </v-card-text>

          <v-card-text>
            <canvas :id="`barChart`"></canvas>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  async beforeCreate() {
    this.$store.state.socket.emit("appartments", {});
    this.$store.state.socket.emit("houses", {});
    this.$store.state.socket.emit("switches", {});
  },
  methods: {
    changeSwitch(selectedHouse, selectedAppartment) {
      console.log(
        this.$store.state.switches[selectedHouse][selectedAppartment]
      );
      this.$store.commit("changeSwitch", [selectedHouse, selectedAppartment]);
    },
    create_demo_chart() {
      const data = {
        labels: [
          "pH",
          "Leitfähigkeit",
          "Gelöster Sauerstoff",
          "Trübung",
          "Chlor",
          "Brom",
          "Nitrat",
          "Summenparameter",
        ],
        datasets: [
          {
            label: "Metrik Zusammenfassung",
            data: [98, 81, 32, 93, 100, 87, 93, 86],
            fill: true,
            backgroundColor: "rgba(252, 238, 79, 0.2)",
            borderColor: "rgba(252, 238, 79, 1.0)",
            pointBackgroundColor: "rgba(252, 238, 79, 1)",
            pointBorderColor: "rgba(0,0,0, 0.2)",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0, 0,0)",
          },
        ],
      };
      const config = {
        type: "radar",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              angleLines: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              pointLabels: {
                color: "rgba(252, 238, 79, 1)",
              },
              ticks: {
                display: false,
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 3,
            },
          },
        },
      };
      setTimeout(function () {
        new Chart(document.getElementById(`testChart`), config);
      }, 200);
    },
  },
  created(){
    this.create_demo_chart()
  },
  data: () => ({
    selectedHouse: 0,
    selectedAppartment: 0,
    wasser_quali_werte: {
      "pH-Wert": 7.5,
      Leitfähigkeit: 86,
      "Gelöster Sauerstoff": "12mg/ml",
      Trübung: "55%",
      Chlor: "56mg/ml",
      Legionellen: "/100ml",
      Nitrat: "28mg/L",
      Summenparameter: 5,
    },
  }),
};
</script>

<style>
.top-right {
  position: absolute;
  top: 0;
  right: 10px;
}

</style>
