import { render, staticRenderFns } from "./Wasserquali.vue?vue&type=template&id=11709cd3&scoped=true&"
import script from "./Wasserquali.vue?vue&type=script&lang=js&"
export * from "./Wasserquali.vue?vue&type=script&lang=js&"
import style0 from "./Wasserquali.vue?vue&type=style&index=0&id=11709cd3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11709cd3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRating,VSimpleTable})
