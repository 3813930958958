import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // eslint-disable-next-line no-undef
    socket: io.connect('https://vps2.josuakoehler.de:2345'),
    upload_endpoint: "https://vps2.josuakoehler.de:2345",
    logged_in: false,
    username: "",

    colors: {
      energy: [252, 238, 79],
      water: [80, 180, 255],
    },

    switches: [[{ Name: "Laden...", Status: false }]],
    appartments: [
      [
        { Mieter: "Laden...", Telefon: "Laden..." }
      ],
    ],
    warnung: {
      "Icon": "loading",
      "Color": "white",
      "Messages": [
        { "Icon": "loading", "Message": "Laden..." },
        { "Icon": "loading", "Message": "Laden..." }
      ]
    },
    houses: [
      {
        "Name": "Laden...",
        "Straße": "Laden...",
        "Ort": "Laden...",
        "Icon": "loading"
      }
    ],
    insurance_send: false,
    chats: [
      {
        "Name": "Laden...", "Picture": "https://st4.depositphotos.com/7662228/21420/v/1600/depositphotos_214208868-stock-illustration-loading-icon-load-system-data.jpg",
        "Unread": true,
        "Messages": [
          { "Text": "Laden...", "In": true },
        ]
      },
    ]
  },
  mutations: {
    changeSwitch(state, [house_idx, appartment_idx]) {
      state.socket.emit("switches_update", {
        "house_idx": house_idx,
        "appartment_idx": appartment_idx,
        "status": state.switches[house_idx][appartment_idx]["Status"]
      });
    },
    setSwitches(state, new_switches) {
      state.switches = new_switches
    },
    setAppartments(state, new_appartments) {
      state.appartments = new_appartments
    },
    setHouses(state, new_houses) {
      state.houses = new_houses
    },
    sendMessage(state, [new_message, chat_id, incoming]) {
      state.socket.emit("add_message", {
        "chat_id": chat_id,
        "message": { Text: new_message, In: incoming }
      });
    },
    addMessage(state, data) {
      state.chats[data.chat_id].Messages.push(data.message)
    },
    setChats(state, new_chats) {
      state.chats = new_chats
    },
    set_warnung(state, new_warnung) {
      state.warnung = new_warnung
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    appartment_count(state) {
      state.appartments.reduce((acc, current_val) => acc.length + current_val, 0)
    },
    unread_chats(state) {
      console.log(JSON.stringify(state.chats.reduce((acc, current_val) => acc + current_val.Unread ? 1 : 0, 0)))
      return state.chats.reduce((acc, current_val) => acc + current_val.Unread ? 1 : 0, 0)
    }
  }
})
