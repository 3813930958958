<template>
  <div>
      <v-switch
        v-model="$vuetify.theme.dark"
        hint="Theme Dark"
        inset
        label="Theme Dark"
        persistent-hint
      ></v-switch>
      <v-btn @click="alarm('alarm')">
        ALARMMM!!!
      </v-btn>
      <v-btn @click="alarm('warnung')">
        UFFF! Alles okay!
      </v-btn>
  </div>
</template>

<script>
export default {
  async beforeCreate() {
  
  },
  methods: {
    alarm(message){
      this.$store.state.socket.emit("new_warnung", {
        new_warnung: message
      });
    }
  }
}
</script>

<style>

</style>