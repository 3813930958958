<template>
  <div class="warnungen_wrapper">
    <h1>Warnungen</h1>
    <div class="warnungen">
      <v-card
        v-for="(warnung, idx) in this.$store.state.warnung.Messages"
        :key="idx"
        class="card warnung_card"
      >
        <div class="card_header">
          <div class="material-icons-round card_icon">{{ warnung.Icon }}</div>
          <h2 class="card-headline">{{ warnung.Message }}</h2>
          <div></div>
        </div>

        <div class="content">
          <div class="contact">
            <div class="material-icons-round icon_content">person</div>
            <div>
              {{
                $store.state.appartments[warnung.House][warnung.Appartment]
                  .Name
              }}<br />
              {{
                $store.state.appartments[warnung.House][warnung.Appartment]
                  .Mieter
              }}<br />
              {{
                $store.state.appartments[warnung.House][warnung.Appartment]
                  .Telefon
              }}
            </div>
          </div>
          <div class="contact">
            <div class="material-icons-round icon_content">
              {{ $store.state.houses[warnung.House].Icon }}
            </div>
            <div>
              {{ $store.state.houses[warnung.House].Name }}<br />
              {{ $store.state.houses[warnung.House].Straße }}<br />
              {{ $store.state.houses[warnung.House].Ort }}
            </div>
          </div>
        </div>
        <div class="log">
          <p v-for="line in warnung.Log" :key="line">{{ line }}</p>
        </div>
          <div class="button_wrapper">
          <router-link to="/versicherung" v-if="warnung.Icon === 'error'">
            <v-btn style="align-self: flex-end">
              Versicherung melden <v-icon right dark :color="$store.state.insurance_send ? 'green' : 'white'"> mdi-cloud-upload </v-icon>
            </v-btn></router-link
          >
          <v-btn style="align-self: flex-end" @click="alarm('resolved')">
            Fehler behoben! <v-icon right dark> mdi-check-circle </v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  async beforeCreate() {
    this.$store.state.socket.emit("appartments", {});
    this.$store.state.socket.emit("houses", {});
    this.$store.state.socket.emit("switches", {});
  },
  methods: {
    alarm(message) {
      this.$store.state.socket.emit("new_warnung", {
        new_warnung: message,
      });
    },
  },
};
</script>

<style scoped>
.warnungen_wrapper {
  padding: 1rem;
}
.warnungen {
  display: flex;
  flex-direction: row;
}
.warnung_card {
  width: 30%;
  margin: 1rem;
}
.contact {
  display: flex;
  flex-direction: row;
}

.icon_content {
  margin-right: 1rem;
  color: var(--primary-color);
}
.content {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.log {
  padding: 1rem;
  margin: 1rem;
  background-color: rgb(70, 70, 70);
  border-radius: 8px;
  color: white;
}
.button_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>