<template>
  <div class="card-item-content">
    <div class="card-switch-left">
      <v-simple-table dark class="card-table">
        <tbody>
          <tr
            v-for="(item, idx) in $store.state.houses"
            :key="idx"
            @click="selected_switch = idx"
            :class="{ col_selected: idx == selected_switch }"
          >
            <td>
              <div class="card-switch-left-content">
                <span class="material-icons-round card-switch-left-icon">{{
                  item.Icon
                }}</span>
                <div class="card-switch-left-text">
                  {{ item.Name }}<br />
                  {{ item.Straße }}<br />
                  {{ item.Ort }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div class="card-switch-right">
      <div
        class="switch-item"
        v-for="(current_appartment, idx) in $store.state.switches[
          selected_switch
        ]"
        :key="idx"
      >
        <div class="as_col">
          {{ $store.state.appartments[selected_switch][idx]["Name"] }}<br />
          <span class="sideinfo"
            >{{ $store.state.appartments[selected_switch][idx]["Mieter"]
            }}<br />
            {{
              $store.state.appartments[selected_switch][idx]["Telefon"] 
            }}</span
          >
        </div>
        <v-switch
          v-model="$store.state.switches[selected_switch][idx]['Status']"
          @click="changeSwitch(selected_switch, idx)"
          inset
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    changeSwitch(house_idx, appartment_idx) {
      console.log(this.$store.state.switches[house_idx][appartment_idx]);
      this.$store.commit("changeSwitch", [house_idx, appartment_idx]);
    },
  },
  data() {
    return {
      selected_switch: 0,
    };
  },
};
</script>

<style>
.card-switch-right {
  width: 60%;
  padding-left: 1rem;
  overflow-y: scroll;
}

.switch-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch-item:not(:last-of-type) {
  border-bottom: solid 1px rgba(161, 161, 161, 0.4);
}
.card-switch-left {
  width: 40%;
  border-right: solid 1px rgba(161, 161, 161, 0.4);
}

.card-switch-left-text {
  text-align: left;
  margin: 0.2rem 0 0.2rem 0.5rem;
}

.card-switch-left-content {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.card-switch-left-icon {
  height: min-content;
  align-self: center;
  transition: all 0.2s ease-in-out;
}
</style>