<template>
  <v-app>
    <v-app-bar app color="dark" dark>
      <div class="d-flex align-center">
        <v-btn text to="/">
          <v-img
            alt="Viega Logo"
            class="shrink mr-2"
            contain
            src="https://www.karriere-familienunternehmen.de/sites/default/files/styles/logoct/public/kundenlogos/viega-logo.png?1622813698"
            transition="scale-transition"
            width="65"
          />

          <h2>
            <span class="highlight">S</span>mart
            <span class="highlight">S</span>ervices
          </h2>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-btn to="/real-estate/list" text>
        <span class="mr-2">Meine Objekte</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-expand-transition>
      <v-alert
        border="top"
        color="error"
        style="margin: 1rem 1.5rem 0 1.5rem"
        dark
        v-show="$store.state.warnung.Color == 'red'"
        >{{ $store.state.warnung.Messages[0].Message }}</v-alert
      >
      </v-expand-transition>
      <router-view />
    </v-main>
    <div class="mobile">
      <span
        ><span class="highlight">Viega Smart Services</span> unterstützt zur Zeit noch
        keine mobilen Endgeräte.</span
      >
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
  async mounted() {
    this.$store.state.socket.emit("warnung", {});
    this.$store.state.socket.on("switches", (data) => {
      this.$store.commit("setSwitches", data);
    });
    this.$store.state.socket.on("appartments", (data) => {
      this.$store.commit("setAppartments", data);
    });
    this.$store.state.socket.on("houses", (data) => {
      this.$store.commit("setHouses", data);
    });
    this.$store.state.socket.on("chats", (data) => {
      this.$store.commit("setChats", data);
    });
    this.$store.state.socket.on("add_message", (data) => {
      this.$store.commit("addMessage", data);
    });
    this.$store.state.socket.on("warnung", (data) => {
      this.$store.commit("set_warnung", data);
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from;
        console.log(to);
        document.title = `VIEGA - ${to.name}` || "VIEGA";
      },
    },
  },
};
</script>

<style>
:root {
  --primary-color: rgb(252, 238, 79);
}

*::selection {
  background-color: var(--primary-color);
  color: rgb(37, 37, 37);
}
.v-card:focus::before {
  opacity: 0 !important;
}
</style>
<style scoped>
h2 {
  font-weight: 400;
}
.highlight {
  color: var(--primary-color);
  font-weight: 700;
}

.mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .v-main {
    display: none;
  }
  .mobile {
    display: grid;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-content: center;
  }
}
</style>