<template>
  <div class="message-wrapper">
    <div class="message-left">
      <v-simple-table dark class="card-table">
        <tbody>
          <tr
            v-for="(item, idx) in $store.state.chats"
            :key="idx"
            @click="selected_switch = idx"
            :class="{ col_selected: idx == selected_switch }"
          >
            <td>
              <div class="card-switch-left-content chat-item">
                <img :src="item.Picture" class="message-picture" />
                <div class="chat-details">
                  <div class="chat-name">
                    {{ item.Name }}
                  </div>
                  <p class="chat-preview">
                    {{ item.Messages.slice(-1)[0].Text.slice(0, 100) }}
                    {{ item.Messages.slice(-1)[0].Text.length > 100 ? "..." : ""}}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-card class="message-right" elevation="2">
      <div class="message-right-header">
        <h2 class="message-right-header-name">
          {{ $store.state.chats[selected_switch].Name }}
        </h2>
        <span
          class="material-icons-round message-header-first-icon"
          :style="{
            color: incoming ? 'white' : 'var(--primary-color)',
            cursor: 'pointer',
          }"
          @click="incoming = !incoming"
          >social_distance</span
        >
      </div>
      <div class="message-window" id="message-window">
        <div
          class="message"
          v-for="(m, idx) in $store.state.chats[selected_switch].Messages"
          :key="idx"
          :class="m.In ? 'message-in' : 'message-out'"
        >
          <span>{{ m.Text }}</span>
        </div>
      </div>
      <form class="message-right-footer" @submit.prevent="send_message">
        <input
          type="text"
          name="chat"
          id="chat-input"
          v-model="current_message"
        />
        <div class="chat-send">
          <button class="material-icons-round">send</button>
        </div>
      </form>
    </v-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selected_switch: 0,
      current_message: "",
      incoming: false,
    };
  },
  methods: {
    send_message() {
      if (this.current_message.length > 0) {
        this.$store.commit("sendMessage", [
          this.current_message,
          this.selected_switch,
          this.incoming,
        ]);
        this.current_message = "";
      }
    },
  },

  created() {
    this.$store.state.socket.emit("chats", {});
  },
};
</script>

<style scoped>
.message-wrapper {
  height: calc(100vh - 72px);
  width: 100%;
  display: flex;
  flex-direction: row;
}
.message-left {
  padding: 1rem 1rem 1rem 1rem;
  width: 30%;
  height: 100%;
}
.message-right {
  flex: 1;
  padding: 0rem 0 0rem 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}
.message-right-header {
  background-color: rgba(255, 255, 255, 0.2);
  height: 3.5rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.message-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  overflow-y: scroll;
}
.message {
  padding: 0.6rem;
  border-radius: 12px;
  background-color: lightcoral;
  margin: 0.4rem;
  width: fit-content;
  max-width: 70%;
}
.message-in {
  background-color: rgba(255, 255, 255, 0.2);
}
.message-out {
  align-self: flex-end;
  background-color: var(--primary-color);
  color: black;
}
.message-right-footer {
  background-color: rgba(255, 255, 255, 0.2);
  height: min-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.message-picture {
  height: 4.5rem;
  border-radius: 50%;
}
.chat-item {
  padding: 0.7rem 0 0.7rem 0;
  align-items: center;
}
.chat-name {
  text-align: left;
  font-size: 1.1rem;
}
.chat-details {
  width: 100%;
  margin: 0rem 0 0rem 0.9rem;
}
.chat-preview {
  overflow: hiddnen;
  text-overflow: ellipsis;
}
.col_selected .chat-name {
  color: var(--primary-color);
}
#chat-input {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  height: 2.5rem;
  margin: 0.7rem;
  padding: 1rem;
  width: 80%;
  color: white;
}

.chat-send {
  vertical-align: bottom;
  display: flex;
  align-items: center;
}
.chat-send > span {
  font-size: 2.4rem;
}
.message-right-header-name {
  color: var(--primary-color);
  font-weight: 300;
}
.message-header-first-icon {
  margin-left: auto;
}
</style>